import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Layout, Menu, Icon} from 'antd';

import {getSortedEnabledCategoryIds} from '../../selectors/selectableCategories'

import './Header.css';
import SelfTestHeader from '../SelfTest/SelfTestHeader'

const {Header} = Layout;

class FDHeader extends Component {

    render() {
        const {enabled_category_ids, categories, location} = this.props;

        const ids = (enabled_category_ids.length !== categories.length) ?
            enabled_category_ids : [];

        const menuItems = [{
            key: "1",
            href: `/overview/${ids.join(',')}`,
            title: 'Home',
        }, {
            key: "2",
            href: `/map/${ids.join(',')}`,
            title: 'Map',
        }, {
            key: "3",
            title: (<span>Specific tests <Icon type="down"/></span>),
            subMenu: [{
                key: "3.1",
                title: "GENI Tutorial tests",
                href: "https://flsmonitor.fed4fire.eu/genitests",
            }, {
                key: "3.2",
                title: "Stitching tests",
                href: "https://flsmonitor.fed4fire.eu/results?testdefinitionname=stitch",
            }, {
                key: "3.3",
                title: "Wilab1 tests",
                href: "/wilab/",
            }, {
                key: "3.4",
                divider: true,
            }, {
                key: "3.5",
                href: "/history/1327",
                title: "F-Interop SDK test"
            }, {
                key: "3.6",
                href: "/history/1413",
                title: "F-Interop IoT tests"
            },
            ]
        }, {
            key: "4",
            href: "http://doc.fed4fire.eu",
            title: 'Documentation',
        },];


        function renderMenuItem(menuItem) {
            if (menuItem.divider)
                return (<Menu.Divider key={menuItem.key}/>);

            if (menuItem.subMenu) {
                return (<Menu.SubMenu key={menuItem.key}
                                      title={menuItem.title}>{menuItem.subMenu.map(renderMenuItem)}</Menu.SubMenu>)
            }

            let result = menuItem.title;
            if (menuItem.href && menuItem.href.startsWith('/')) {
                result = (<Link to={menuItem.href}>{result}</Link>)
            } else {
                result = (<a href={menuItem.href}>{result}</a>)
            }
            return (<Menu.Item key={menuItem.key}>{result}</Menu.Item>)
        }

        function getSelectedKeys() {
            const selectedKeys = [];

            menuItems.forEach(menuItem => {
                if (menuItem.href && menuItem.href === location.pathname) {
                    selectedKeys.push(menuItem.key)
                } else {
                }
            });

            return selectedKeys;
        }

        return (
            <Header className="header">
                <div className="header-logo">
                    <Link to="/">
                        <img src="/assets/img/fedmon-logo.svg" alt=""/> Fed4FIRE Federation Monitor
                    </Link>
                </div>

                <Menu theme="dark" mode="horizontal" className="fedmon-header-menu" selectedKeys={getSelectedKeys()}>
                    {menuItems.map(renderMenuItem)}
                </Menu>
                <div className="header-status">
                    <SelfTestHeader/>
                </div>
            </Header>
        );
    }
}

FDHeader.propTypes = {
    location: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        enabled_category_ids: getSortedEnabledCategoryIds(state),
        categories: Object.values(state.selectableCategories)
    }
}

export default withRouter(connect(mapStateToProps)(FDHeader));
