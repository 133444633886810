import React, {Component} from 'react';
import {Icon, Timeline} from 'antd';
import {PropTypes} from 'prop-types';
import FedmonUtils from '../../utils';
import moment from 'moment';
import DashboardPanel from "../../components/DashboardPanel/DashboardPanel";


export default class NodeLogin extends Component {

    static propTypes = {
        testResult: PropTypes.object
    };

    render() {
        const {testResult} = this.props;

        let content = [];
        let footer = [];

        if (testResult) {
            const {created} = testResult;
            footer.push("Last test was performed at " + moment(created).format('D MMMM YYYY HH:mm') + " and took " + moment.duration({ms: testResult.results.timing.durationMs}).humanize());

            if (testResult.results.steps) {
                content = (<Timeline>{testResult.results.steps.map(step =>
                    (<Timeline.Item key={step.name}
                                    dot={FedmonUtils.getFontAwesomeFromStatus(step.state)}>{step.name}</Timeline.Item>))}</Timeline>);
            } else {
                content = (<span><Icon type="exclamation-circle"  theme="twoTone" twoToneColor="#eb2f96"/> Not available</span>);
            }
        } else {
            content = (<span><Icon type="warning" theme="twoTone" twoToneColor="#fa8c16"/> Not available</span>);
        }

        return (
            <DashboardPanel title="Node Login" loading={!testResult} footer={footer}>
                {content}
            </DashboardPanel>
        );


    }
}