import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {Map, Marker, TileLayer} from 'react-leaflet';
import AbstractChart from "./AbstractChart";

// import 'leaflet/dist/leaflet.css'
import './Location.css'


export default class Location extends Component {

    static propTypes = {
        location: PropTypes.object
    };

    render() {
        const {location} = this.props;
        let chartStage = [];
        let chartNotes = [];

        if (!location) {
            chartStage = (<h4>Unknown</h4>);
        } else {
            const {longitude, latitude, address} = location;
            const position = [latitude, longitude];

            chartStage = (
                <Map center={position} zoom={8} className="location-leaflet-container">

                    <TileLayer
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy;
        <a href="https://carto.com/attribution">CARTO</a>'
                        url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                    />
                    <Marker position={position}/>
                </Map>);

            chartNotes.push("Address: ");
            chartNotes.push(address ? address : "N/A");
        }

        return (<AbstractChart title="Location" footer={chartNotes}>{chartStage}</AbstractChart>);


    }
}