import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card} from 'antd';
import {PropTypes} from 'prop-types';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faHistory, faStepForward} from '@fortawesome/fontawesome-free-solid';
import {Button} from 'antd';
import Loading from "../../components/Loading/Loading";

class TestInstancesList extends Component {


    render() {
        const {testInstances, testInstancesIsLoading, lastResultsByTestInstance} = this.props;


        const content = [];

        if (testInstancesIsLoading) {
            content.push((<Loading/>));
        } else if (!testInstances) {
            content.push((<div key="none">None available</div>));
        } else {
            testInstances.forEach(testInstance => {
                const lastResultId = lastResultsByTestInstance[testInstance['@id']] ? lastResultsByTestInstance[testInstance['@id']].id : 0;
                content.push((
                    <li key={testInstance.id}>{testInstance.name}
                        <span> </span>
                        <Button.Group size="small">
                            <Button alt="Browse History" href={`/history/${testInstance.id}`}>
                                <FontAwesomeIcon
                                    icon={faHistory}/></Button>
                            <Button alt="Last Result" disabled={lastResultId === 0}
                                    href={`/result/${lastResultId}`}><FontAwesomeIcon
                                icon={faStepForward}/></Button>
                        </Button.Group>
                    </li>));
            })
        }

        return (
            <Card title="Tests" loading={testInstancesIsLoading}><div style={{maxHeight: 450, overflowY: "auto"}}>{content}</div></Card>
        );

    }

}

TestInstancesList.propTypes = {
    testbedId: PropTypes.string.isRequired,
    testInstances: PropTypes.array,
    testInstancesIsLoading: PropTypes.bool.isRequired,
    lastResultsByTestInstance: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const testbedId = ownProps.testbedId;

    const testInstances = state.testInstances.byTestbedId[testbedId];
    const testInstancesIsLoading = state.testInstances.isLoading.indexOf(testbedId) >= 0;

    const lastResults = state.latestResults.byTestbedId[testbedId];
    const lastResultsByTestInstance = {};
    if (lastResults) {
        Object.values(lastResults).forEach(result => {
            lastResultsByTestInstance[result.testInstance] = result;
        })
    }

    return {
        testInstances,
        testInstancesIsLoading,
        lastResultsByTestInstance
    }
}

export default connect(mapStateToProps)(TestInstancesList);