import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import moment from 'moment';
import CalendarHeatmap from '../../components/CalendarHeatmap';
import {DashboardPanel} from "../../components/DashboardPanel";
import {loadRrdIfNeeded} from "../../actions/rrd";
import {getRrdByTestDefinitionAndPeriod, TestNames, Periods} from "../../selectors/rrd";
import {loadTestbedIfNeeded} from "../../actions/testbeds";


class AggregateStatusChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeRrdId: null,
        }
    }

    componentDidMount() {
        const {dispatch, testbedId} = this.props;

        dispatch(loadTestbedIfNeeded(testbedId));
    }

    componentDidUpdate(prevProps, prevState) {
        const {dispatch, testbedId} = this.props;
        const {activeRrdId} = this.state;

        if (testbedId && testbedId !== prevProps.testbedId) {
            dispatch(loadTestbedIfNeeded(testbedId));
        }

        if (activeRrdId && activeRrdId !== prevState.activeRrdId) {
            dispatch(loadRrdIfNeeded(activeRrdId));
        }
    }

    componentWillReceiveProps(nextProps) {
        const {yearRrdId} = nextProps;
        let {activeRrdId} = this.state;


        if (!activeRrdId && yearRrdId) {
            activeRrdId = yearRrdId;

            this.setState({
                activeRrdId
            });
        }
    }

    static NUMBER_OF_COLORS = 4;


    render() {
        const {rrdData} = this.props;
        const {activeRrdId} = this.state;

        const activeRrdData = rrdData[activeRrdId];

        const values = activeRrdData ? activeRrdData.map(result => {
            return {...result, date: result.from}
        }) : [];

        let footer = null;

        if (activeRrdData && activeRrdData.length > 0) {
            footer = `Showing aggregate status between ${moment(activeRrdData[0].from).format('D MMMM YYYY')} until ${moment(activeRrdData[activeRrdData.length - 1].from).format('D MMMM YYYY')}`;
        }

        const classForValue = (value) => {
            if (!value) {
                return 'color-empty';
            }
            const downtimePercentage = Math.min(value.downtime / (24 * 60 * 60 * 1000), 1);
            const colorNumber = Math.round(AggregateStatusChart.NUMBER_OF_COLORS * (1 - downtimePercentage));

            return `aggregate-color-${colorNumber}`;
        };

        const customTitleForValue = (value) => {
            if (!value)
                return "No info available";

            return "On " + moment(value.date).format('D MMMM YYYY') + " there was " + (value.downtime > 0 ? "a downtime of " + moment.duration(value.downtime).humanize() : 'no downtime');
        };

        const title = ["Aggregate Status"];

        return (
            <DashboardPanel loading={!activeRrdData} title={title} footer={footer}>
                {activeRrdData ?
                    (activeRrdData.length > 0 ? (<CalendarHeatmap
                            endDate={values[values.length - 1].date}
                            numDays={365}
                            values={values}
                            horizontal={true}
                            classForValue={classForValue}
                            titleForValue={customTitleForValue}/>)
                        : <div>No info available</div>)
                    : []}
            </DashboardPanel>);

    }
}

AggregateStatusChart.propTypes = {
    testbedId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const {testbedId} = ownProps;
    const rrd = state.testbeds.byId[testbedId];

    const rrdData = {};

    let yearRrdId = null;

    if (rrd) {
        yearRrdId = getRrdByTestDefinitionAndPeriod(TestNames.AGGREGATE_STATUS, Periods.ONE_YEAR, rrd);

        rrdData[yearRrdId] = state.rrd.byId[yearRrdId];
    }

    return {yearRrdId, rrdData}
}


export default connect(mapStateToProps)(AggregateStatusChart)