import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faCheckCircle, faExclamationCircle, faStopCircle, faTimesCircle} from '@fortawesome/fontawesome-free-solid';
import {PropTypes} from 'prop-types';
import humanizeDuration from 'humanize-duration';

export default class FedmonUtils {

    static colors = [
        /* teal      red        yellow     purple     orange     mint       blue       green      lavender */
        "#00bbde", "#fe6672", "#eeb058", "#8a8ad6", "#ff855c", "#00cfbb", "#5a9eed", "#73d483", "#c879bb",
        "#0099b6", "#d74d58", "#cb9141", "#6b6bb6", "#d86945", "#00aa99", "#4281c9", "#57b566", "#ac5c9e",
        "#27cceb", "#ff818b", "#f6bf71", "#9b9be1", "#ff9b79", "#26dfcd", "#73aff4", "#87e096", "#d88bcb"
    ];

    static getOpaqueColor(index, opacity) {
        let c = '0x' + FedmonUtils.colors[index].substring(1);
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }


    static getFontAwesomeFromStatus(status) {
        switch (status) {
            case "SUCCESS":
                return ( <FontAwesomeIcon type="success" icon={faCheckCircle} style={{color: 'green'}}/>);
            case "WARNING":
            case "WARN":
                return ( <FontAwesomeIcon type="warn" icon={faExclamationCircle} style={{color: 'orange'}}/>);
            case "FAILURE":
            case "FAILED":
                return ( <FontAwesomeIcon type="failure" icon={faTimesCircle} style={{color: 'orangered'}}/>);
            case "SKIPPED":
            default:
                return ( <FontAwesomeIcon type="skipped" icon={faStopCircle} style={{color: 'gray'}}/>);
        }
    }

    static formatDuration(durationInMs) {
        if (!durationInMs)
            return "";

        else
            return humanizeDuration(durationInMs);
    }


    /**
     * get the url. The object can be a link (so in fact the url), or it can be an embedded object iwth the url in @id
     * @returns the url, or null if there is no object or url. Never returns undefined.
     */
    static getUrl(o) {
        if (typeof o === 'undefined' || o === null) {
            return null;
        }
        if (typeof o === 'string') {
            return o;
        }
        if (typeof o === 'object') {
            if (o.hasOwnProperty('@id')) {
                return o['@id'];
            } else {
                return null;
            }
        }
        throw new Error('Illegal argument: Not expecting type ' + typeof(object));
    }

    /**
     * get the id. The object can be a link (so o is the url),
     *    or it can be an embedded object with the url in '@id' and the id in 'id'
     * @returns the id, or null if there is no object or id. Never returns undefined.
     */
    static getId(o) {
        if (typeof o === 'undefined' || o === null) {
            return null;
        }
        if (typeof o === 'string') {
            return this.getIdFromUrlString(o);
        }
        if (typeof o === 'object') {
            if (o.hasOwnProperty('id')) {
                return o['id'];
            } else if (o.hasOwnProperty('@id')) {
                return this.getIdFromUrlString(o['@id']);
            } else {
                return null;
            }
        }
        throw new Error('Illegal argument: Not expecting type ' + typeof(object));
    }

    /**
     * get the (base) object.
     *    The given object can be a link (so o is the url),
     *    or it can be an embedded object with the url in '@id' and the id in 'id' (which is thus returned unchanged)
     * @returns an object iwith id and @id, or null if there is no object or id. Never returns undefined.
     */
    static getObject(o) {
        if (typeof o === 'undefined' || o === null) {
            return null;
        }
        if (typeof o === 'string') {
            return this.getObjectFromUrlString(o);
        }
        if (typeof o === 'object') {
            if (o.hasOwnProperty('id') && o.hasOwnProperty('@id') && o.hasOwnProperty('@type')) {
                return o;
            } else if (o.hasOwnProperty('@id')) {
                return this.getObjectFromUrlString(o['@id']);
            } else {
                return null;
            }
        }
        throw new Error('Illegal argument: Not expecting type ' + typeof(object));
    }

    static getObjectFromUrlString(url) {
        if (typeof url === 'undefined' || url === null) {
            return null;
        }
        var lastSlash = url.lastIndexOf('/');
        if (lastSlash === -1) {
            return null;
        }
        var typeStartSlash = url.lastIndexOf(lastSlash - 1, '/');
        if (typeStartSlash === -1) {
            return null;
        }
        var id = url.substring(lastSlash + 1, url.length);
        var type = url.substring(typeStartSlash + 1, lastSlash);
        return {
            'id': id,
            '@id': url,
            '@type': type,
        };
    }

    /**
     * extract the object id from a fedmon URL
     *
     * @param url object url
     * @returns the id of the fedmon object (string)
     */
    static getIdFromUrlString(url) {
        if (typeof url === 'undefined' || url === null) {
            return null;
        }
        var lastSlash = url.lastIndexOf('/');
        if (lastSlash === -1) {
            return null;
        }
        return url.substring(lastSlash + 1, url.length);
    }

}


export function createContextProvider(context) {
    class ContextProvider extends React.Component {
        getChildContext() {
            return context;
        }

        render() {
            return this.props.children;
        }
    }

    ContextProvider.childContextTypes = {};
    Object.keys(context).forEach(key => {
        ContextProvider.childContextTypes[key] = PropTypes.any.isRequired;
    });

    return ContextProvider;
}