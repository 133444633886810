import React, {PureComponent} from 'react';
import {PropTypes} from 'prop-types';
import {Card} from 'antd';

class DashboardPanel extends PureComponent {


    render() {
        const {title, children, notes, loading, extra} = this.props;
        return (
            <Card title={title} extra={extra} actions={notes ? [notes] : null} loading={loading}>
                {children}
            </Card>
        );
    }
}

DashboardPanel.propTypes = {
    title: PropTypes.node.isRequired,
    extra: PropTypes.node,
    children: PropTypes.node.isRequired,
    notes: PropTypes.node,
    loading: PropTypes.bool,
};

export default DashboardPanel;