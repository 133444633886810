import * as types from "../actions/actionTypes";
import isEqual from 'lodash.isequal';

//Initialstate
const initialState = {
    "fed4fire": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/359",
        shortId: "fed4fire",
        name: "Fed4FIRE Testbeds",
        enabled: true,
        weight: 110
    },
    "fed4fire_extra": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/346",
        shortId: "fed4fire_extra",
        name: "Testbeds federated with Fed4FIRE",
        enabled: true,
        weight: 111
    },
    "instageni": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/347",
        shortId: "instageni",
        name: "InstaGENI Testbeds",
        enabled: true,
        weight: 120
    },
    "international_federation": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/348",
        shortId: "international_federation",
        name: "Internationally federated testbeds",
        enabled: true,
        weight: 150
    },
    "exogeni": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/349",
        shortId: "exogeni",
        name: "ExoGENI Testbeds",
        enabled: true,
        weight: 121
    },
    "emulab": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/401",
        shortId: "emulab",
        name: "Emulab Testbeds",
        enabled: true,
        weight: 122
    },
    "cloudlab": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/444",
        shortId: "cloudlab",
        name: "Cloudlab Testbeds",
        enabled: true,
        weight: 123
    },
    "felix": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/379",
        shortId: "felix",
        name: "Felix Testbeds",
        enabled: true,
        weight: 124
    },
    "dev": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/364",
        shortId: "dev",
        name: "Testbeds in development",
        enabled: true,
        weight: 199
    },
    "wired": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/470",
        shortId: "wired",
        name: "Wired Testbeds",
        enabled: true,
        weight: 100
    },
    "wireless": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/471",
        shortId: "wireless",
        name: "Wireless Testbeds",
        enabled: true,
        weight: 101
    },
    "openflow": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/472",
        shortId: "openflow",
        name: "OpenFlow Testbeds",
        enabled: true,
        weight: 102
    },
    "cloud": {
        id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/473",
        shortId: "cloud",
        name: "Cloud Testbeds",
        enabled: true,
        weight: 103
    },
    // "other": {
    //     id: "https://flsmonitor-api.fed4fire.eu/testbedcategory/474",
    //     shortId: "other",
    //     name: "Other",
    //     enabled: true,
    //     weight: 200
    // },
};


//Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_CATEGORY_ENABLED:
            return {...state, [action.id]: {...state[action.id], enabled: action.enabled}};

        case types.SET_ENABLED_CATEGORIES:
            const newState = {};

            Object.entries(state).forEach(([id, cat]) => {
                newState[id] = {...cat, enabled: action.enabled_category_ids.includes(id)};
            });


            return isEqual(state, newState) ? state : newState;
        default:
            return state;
    }
}


