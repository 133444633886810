import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import {Switch, Button} from 'antd';

import moment from 'moment';
import ResourcesGraph from './ResourcesGraph';
import AbstractChart from "./AbstractChart";
import {loadRrdIfNeeded} from "../../actions/rrd";
import {loadTestbedIfNeeded} from "../../actions/testbeds";
import {getRrdByTestDefinitionAndPeriod, Periods, TestNames} from "../../selectors/rrd";

class ResourcesChart extends Component {

    static SUPPORTED_TYPES = [
        {id: 'Raw', friendlyName: 'Raw'},
        {id: 'Vm', friendlyName: "VM"},
        {id: 'Ip4', friendlyName: "IPv4"},
        {id: 'Openflow', friendlyName: 'OpenFlow'}
    ];


    static propTypes = {
        resourceUrl: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            activeRrdId: null,
            types: [],
            showAvailable: true,
            showTotal: true
        }
    }

    componentDidMount() {
        const {dispatch, testbedId} = this.props;

        dispatch(loadTestbedIfNeeded(testbedId));
    }

    componentDidUpdate(prevProps, prevState) {
        const {dispatch, testbedId} = this.props;
        const {activeRrdId} = this.state;

        if (testbedId && testbedId !== prevProps.testbedId) {
            dispatch(loadTestbedIfNeeded(testbedId));
        }

        if (activeRrdId && activeRrdId !== prevState.activeRrdId) {
            dispatch(loadRrdIfNeeded(activeRrdId));
        }
    }


    componentWillReceiveProps(nextProps) {
        const {monthRrdId, rrdData} = nextProps;
        let {activeRrdId} = this.state;


        if (!activeRrdId && monthRrdId) {
            activeRrdId = monthRrdId;

            this.setState({
                activeRrdId
            });
        }

        if (rrdData[activeRrdId]) {
            const typesSet = new Set();

            rrdData[activeRrdId].forEach(result => {
                if (result) {
                    ResourcesChart.SUPPORTED_TYPES.forEach(supportedType => {
                        if (result["total" + supportedType.id] && result["total" + supportedType.id] > 0)
                            typesSet.add(supportedType);
                    });
                }
            });

            const types = [];
            typesSet.forEach(type => types.push({
                ...type,
                active: this.state.types[type] ? this.state.types[type].active : true
            }));

            this.setState({
                types
            });
        }
    }


    render() {
        const {yearRrdId, monthRrdId, weekRrdId, rrdData} = this.props;
        const {activeRrdId, types, showAvailable, showTotal} = this.state;

        let activeRrdName = null;
        let timeDivision = null;
        if (activeRrdId === yearRrdId){
            activeRrdName = 'year';
            timeDivision = 'month';
        } else if (activeRrdId === monthRrdId) {
            activeRrdName = 'month';
            timeDivision = 'week';
        } else if (activeRrdId === weekRrdId) {
            activeRrdName='week';
            timeDivision = 'day'
        }

        const checkboxChanged = (index) => {
            let newTypes = types.slice(0);
            newTypes[index].active = !newTypes[index].active;

            this.setState({
                types: newTypes
            })
        };

        const showAvailableChanged = () => {
            this.setState({
                showAvailable: !this.state.showAvailable
            })
        };
        const showTotalChanged = () => {
            this.setState({
                showTotal: !this.state.showTotal
            })
        };

        const setActiveRrdId = (newRrdId) => this.setState({activeRrdId: newRrdId});

        const title = ["Free Resources "];

        const activeRrdData = rrdData[activeRrdId];
        let footer = null;
        const extra = [<span key="periodSelection" style={{marginLeft: '2em', marginRight: '1em'}}>
                <strong>Period: </strong>
                <Button.Group size="small" value={activeRrdName}>
                    <Button value="week" disabled={!weekRrdId} onClick={() => setActiveRrdId(weekRrdId)}>W</Button>
                    <Button value="month" disabled={!monthRrdId} onClick={() => setActiveRrdId(monthRrdId)}>M</Button>
                    <Button value="year" disabled={!yearRrdId} onClick={() => setActiveRrdId(yearRrdId)}>Y</Button>
                </Button.Group></span>];

        if (activeRrdData) {
            extra.push(
                <span key="show-list">
                    <strong>Show: </strong>
                    {types.map((type, index) => (
                        <span key={`span-${type.id}`}>
                        <Switch size="small" key={type.id} checked={type.active}
                                onChange={() => checkboxChanged(index)}/>{type.friendlyName}</span>)
                    )}
                    <strong> - </strong><span>
                    <Switch size="small" checked={showAvailable}
                            onChange={() => showAvailableChanged()}/>Available</span>
                    <span>
                    <Switch size="small" checked={showTotal}
                            onChange={() => showTotalChanged()}/>Total</span>
                </span>
            );

            footer =
                activeRrdData && activeRrdData.length > 0 ?
                    "Showing results between " + moment(activeRrdData[0].datetime).format('D MMMM YYYY HH:mm:ss') + " and " + moment(activeRrdData[activeRrdData.length - 1].datetime).format('D MMMM YYYY HH:mm:ss')
                    : "";

        }


        return (<div>
            <AbstractChart title={title} extra={extra} footer={footer}>
                <ResourcesGraph results={activeRrdData} types={types} showTotal={showTotal}
                                showAvailable={showAvailable} timeDivision={timeDivision}/>
            </AbstractChart>
        </div>);


    }
}


ResourcesChart.propTypes = {
    testbedId: PropTypes.string.isRequired,
};


function mapStateToProps(state, ownProps) {
    const {testbedId} = ownProps;
    const rrd = state.testbeds.byId[testbedId];

    const rrdData = {};

    let yearRrdId = null;
    let monthRrdId = null;
    let weekRrdId = null;

    if (rrd) {
        yearRrdId = getRrdByTestDefinitionAndPeriod(TestNames.LISTRESOURCES, Periods.ONE_YEAR, rrd);
        monthRrdId = getRrdByTestDefinitionAndPeriod(TestNames.LISTRESOURCES, Periods.ONE_MONTH, rrd);
        weekRrdId = getRrdByTestDefinitionAndPeriod(TestNames.LISTRESOURCES, Periods.ONE_WEEK, rrd);

        rrdData[yearRrdId] = state.rrd.byId[yearRrdId];
        rrdData[monthRrdId] = state.rrd.byId[monthRrdId];
        rrdData[weekRrdId] = state.rrd.byId[weekRrdId];
    }

    return {yearRrdId, monthRrdId, weekRrdId, rrdData}
}

export default connect(mapStateToProps)(ResourcesChart);