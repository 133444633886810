import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Layout, Row, Col, Icon} from 'antd'
import PageHeader from 'ant-design-pro/lib/PageHeader';

import {PropTypes} from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faBook, faInfoCircle} from "@fortawesome/fontawesome-free-solid";

import './Testbed.css';

import TestInstanceList from './TestInstancesList';
import PingChart from './PingChart'
import ResourcesChart from './ResourcesChart'
import NodeLogin from './NodeLogin'
import SumNodeLogin from './SumNodeLogin'
import AvailableResources from './AvailableResources'
import Location from './Location'
import FedmonUtils from '../../utils';
import AggregateStatusChart from './AggregateStatusChart';
import Loading from '../../components/Loading';
import {loadTestbedIfNeeded} from '../../actions/testbeds'
import {loadTestInstancesByTestbedIfNeeded} from "../../actions/testInstances";
import {loadLatestResultsByTestbedIfNeeded} from "../../actions/latestTestResults";
import {getTestInstanceIdByTestDefinition, TestNames} from "../../selectors/rrd";
import CardErrorBoundary from "../../CardErrorBoundary";

const {Content} = Layout;

class Testbed extends Component {

    componentWillMount() {
        const {testbedId, dispatch} = this.props;


        dispatch(loadTestbedIfNeeded(testbedId));
        dispatch(loadTestInstancesByTestbedIfNeeded(testbedId));
        dispatch(loadLatestResultsByTestbedIfNeeded(testbedId));
    }

    componentDidUpdate(prevProps) {
        const {testbedId, dispatch} = this.props;

        if (testbedId !== prevProps.testbedId) {

            dispatch(loadTestbedIfNeeded(testbedId));
            dispatch(loadTestInstancesByTestbedIfNeeded(testbedId));
            dispatch(loadLatestResultsByTestbedIfNeeded(testbedId));

        }
    }

    render() {
        const {testbedId, testbed, organisation, firstServer, lastResults, testInstances} = this.props;

        if (!testbed) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>)
        }

        //ideally, server location is used. If there is none, use organisation location as fallback
        const location = firstServer && firstServer.location ? firstServer.location :
            organisation ? organisation.location : null;

        const {longName, infoUrl, helpUrl} = testbed;

        const breadcrumbs = [{
            title: (<Icon type="home"/>),
            href: '/',
        }, {
            title: testbed.longName,
            href: `/testbed/${testbedId}`,
        }];

        return (<div>
            <PageHeader
                className="tabs"
                breadcrumbList={breadcrumbs}
                title={<div className="title">{longName}</div>}
                logo={<div className="testbed-logo">{organisation && organisation.logoUrl ? (
                    <img src={organisation.logoUrl} alt={organisation.name}/>) : (
                    <img src="/assets/img/fed4fire.png" className="placeholder"
                         alt=""/>
                )}</div>}
                action={<div className="action">
                    {infoUrl ? (<a className="action-item" href={infoUrl} alt="More info">
                        <FontAwesomeIcon icon={faInfoCircle} alt="More info"/></a>) : []}

                    {helpUrl ? (<a className="action-item" href={helpUrl} alt="Documentation">
                        <FontAwesomeIcon icon={faBook} alt="Documentation"/></a>) : []}
                </div>}
                content={<div className="content">{organisation ?
                    (<span><a href={organisation.siteUrl}>{organisation.name}</a></span>) : []}</div>}
                extraContent={<div className="extraContent"></div>}
            />
            <div>
                <Row gutter={16} style={{margin: 24}}>
                    <Col md={6} sm={12} style={{marginBottom: 24}}>
                        <CardErrorBoundary title="Tests">
                            <TestInstanceList testbedId={testbedId}/>
                        </CardErrorBoundary>
                    </Col>

                    <Col md={6} sm={12} style={{marginBottom: 24}}>
                        <CardErrorBoundary title="Location">
                            <Location location={location}/>
                        </CardErrorBoundary>
                    </Col>
                    <Col md={6} sm={12} style={{marginBottom: 24}}>

                        <CardErrorBoundary title="Node login">
                            {findTestByDefinitionName(lastResults, "repl_sum_nodelogin") ?
                                <SumNodeLogin
                                    testResult={findTestByDefinitionName(lastResults, "repl_sum_nodelogin")}/>
                                :

                                findTestByDefinitionName(lastResults, "nodelogin2") ?
                                    <NodeLogin testResult={findTestByDefinitionName(lastResults, "nodelogin2")}/> :
                                    <NodeLogin testResult={findTestByDefinitionName(lastResults, "nodelogin")}/>}
                        </CardErrorBoundary>
                    </Col>
                    <Col md={6} sm={12} style={{marginBottom: 24}}>

                        <CardErrorBoundary title="Available resources">
                            <AvailableResources
                                testResult={findTestByDefinitionName(lastResults, "listResources")}/>
                        </CardErrorBoundary>
                    </Col>
                </Row>
                <Row gutter={16} style={{margin: 24}}>
                    <Col md={12} sm={24} style={{marginBottom: 24}}>
                        <CardErrorBoundary tests="Free Resources">
                            <ResourcesChart testbedId={testbedId}/></CardErrorBoundary>
                    </Col>
                    <Col md={12} sm={24} style={{marginBottom: 24}}>
                        <CardErrorBoundary title="Aggregate status">
                            <AggregateStatusChart testbedId={testbedId}/>
                        </CardErrorBoundary>
                    </Col>
                    <Col md={12} sm={24} style={{marginBottom: 24}}>

                        <CardErrorBoundary title="Ping">
                            <PingChart
                                testInstanceId={getTestInstanceIdByTestDefinition(TestNames.PING, testInstances)}/>
                        </CardErrorBoundary>
                    </Col>
                </Row>
            </div>
        </div>);
    }
}

function findTestByDefinitionName(results, testDefinitionName) {
    if (results) {

        const key = Object.keys(results).find(tdId => FedmonUtils.getIdFromUrlString(tdId) === testDefinitionName);

        return results[key];
    } else
        return null;
}

function findTestbedById(testbedId, testbeds) {
    return Object.values(testbeds.byId).find(testbed => testbed.id === testbedId);
}

Testbed.propTypes = {
    testbedId: PropTypes.string.isRequired,
    testbed: PropTypes.object,
    firstServer: PropTypes.object,
    organisation: PropTypes.object,

    testInstances: PropTypes.array,
    testInstancesIsLoading: PropTypes.bool.isRequired,

    lastResults: PropTypes.object,
    lastResultsIsLoading: PropTypes.bool.isRequired,
    lastResultsByTestInstance: PropTypes.object,

    rrd: PropTypes.array,
    rrdIsLoading: PropTypes.bool.isRequired
};


function mapStateToProps(state, ownProps) {
    const testbedId = ownProps.match.params.testbedId;
    const testbed = findTestbedById(testbedId, state.coreInfo.testbeds);
    const organisation = testbed ? state.coreInfo.organisations.byId[testbed.organisation] : null;
    const firstServer = testbed && testbed.servers && testbed.servers.length > 0 ? state.coreInfo.servers.byId[testbed.servers[0]] : undefined;

    const testInstances = state.testInstances.byTestbedId[testbedId];
    const testInstancesIsLoading = state.testInstances.isLoading.indexOf(testbedId) >= 0;

    const lastResults = state.latestResults.byTestbedId[testbedId];
    const lastResultsIsLoading = state.latestResults.isLoading.includes(testbedId);
    const lastResultsByTestInstance = {};
    if (lastResults) {
        Object.values(lastResults).forEach(result => {
            lastResultsByTestInstance[result.testInstance] = result;
        })
    }
    const rrd = state.testbeds.byId[testbedId];
    const rrdIsLoading = state.testbeds.isLoading.indexOf(testbedId) >= 0;

    return {
        testbedId,
        testbed,
        organisation,
        firstServer,
        testInstances,
        testInstancesIsLoading,
        lastResults,
        lastResultsIsLoading,
        lastResultsByTestInstance,
        rrd,
        rrdIsLoading
    }

}

export default connect(mapStateToProps)(Testbed);
