import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {Button} from 'antd';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faTimesCircle, faFileCode} from '@fortawesome/fontawesome-free-solid'

import Gauge from '../../components/Gauge';
import moment from 'moment';
import DashboardPanel from "../../components/DashboardPanel/DashboardPanel";


export default class AvailableResources extends Component {

    static propTypes = {
        testResult: PropTypes.object
    };

    render() {
        const {testResult} = this.props;

        let chartStage = [];
        let chartNotes = [];

        if (testResult) {
            if ("SUCCESS" === testResult.summary) {
                const {created} = testResult;

                Object.keys(testResult.results.count)
                    .filter(key => key !== "summary")
                    .forEach(key => {
                        const count = testResult.results.count[key];

                        if (count.total > 0)
                            chartStage.push((<Gauge key={key}
                                                    value={count.available}
                                                    max={count.total}
                                                    label={key}
                                                    width={200}
                                                    height={125}
                                                    valueLabelStyle={{
                                                        fontSize: 25
                                                    }}
                                                    topLabelStyle={{
                                                        color: '#2e2e2e',
                                                        fontSize: 20
                                                    }}
                            />));

                    });

                chartNotes.push("Last check was performed at " + moment(created).format('D MMMM YYYY HH:mm') + " and took " + moment.duration({ms: testResult.results.timing.durationMs}).humanize())
            } else {
                chartStage = (
                    <p><FontAwesomeIcon icon={faTimesCircle} style={{color: 'orangered'}}/> Fetching available resources
                        failed
                    </p>);
            }
        }

        return (
            <DashboardPanel title="Available resources" loading={!testResult}
                            extra={
                                testResult && testResult.results && testResult.results.rspecUrl ? (
                                    <Button size="small" href={testResult.results.rspecUrl}><FontAwesomeIcon
                                        icon={faFileCode}/> Source</Button>) : []

                            }
                            footer={chartNotes}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {chartStage}
                </div>
            </DashboardPanel>
        );
    }
}